import React from "react";
import { Route } from "react-router";
import Portfolio from "./components/portfolio/portfolio";
import { Contact } from "./components/contact/contact";
import ProjectBrowser from "./components/project-browser/project-browser";
import { Home } from "./components/home/home";
import { Switch } from "react-router-dom";
import About from "./components/about/about";
import Offer from "./components/offer/offer";

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/o-nas" component={About} />
      <Route exact path="/portfolio" component={Portfolio} />
      <Route path="/portfolio/:id" component={ProjectBrowser} />
      <Route path="/kontakt" component={Contact} />
      <Route exact path="/oferta" component={Offer} />
    </Switch>
  );
}

export default Routes;
