import React from "react";
import image from "../../../assets/hexagon.png";
import { Grow } from "@material-ui/core";
import "./hexagon.css";

function Hexagon(props) {
  return (
    <Grow in={props.shouldRender} timeout={1000}>
      <img className="shape" src={image} alt="" />
    </Grow>
  );
}

export default Hexagon;
