export const intro1 =
  "Projektowanie wnętrz to nasza specjalność, Nasze projekty są nie tylko odwzorowaniem aktualnej mody i trendów ale również odzwierciedleniem potrzeb i oczekiwań ich użytkowników. W każdym projekcie łączymy funkcjonalność, ergonomię i praktyczność rozwiązań z estetyką, ładem i harmonią. Nie boimy się wyzwań, realizujemy duże i trudne zadania, ale również podejmujemy tematy mniejsze, np. projekty poszczególnych pomieszczeń.";

export const intro2 =
  "Dostosowujemy swoją pracę do Państwa oczekiwań i wychodząc im naprzeciw pracujemy na kilku stopniach zaawansowania projektu aranżacji wnętrz:";

export const case1 =
  "PROJEKT PODSTAWOWY- obejmujący układy aranżacyjne (ok. 3 wariantów) w formie rzutów funkcjonalnych ze wskazaniem wymiarów i lokalizacji poszczególnych elementów.";

export const case2 =
  "PROJEKT KONCEPCYJNY- obejmujący projekt podstawowy oraz wizualizacje przygotowane na podstawie wybranego wariantu układu funkcjonalnego";

export const case3 =
  "PROJEKT KOMPLEKSOWY- obejmujący projekt koncepcyjny wraz z wszystkimi niezbędnymi do realizacji projektu rysunkami technicznymi (rysunki instalacyjne, zabudowy meblowe etc.). W ramach projektu kompleksowego pracujemy z państwem na konkretnych próbkach materiałów oraz dbamy o utrzymanie założonego budżetu oraz pomagamy uzyskać wyceny u poszczególnych wykonawców";

export const case4 =
  "NADZÓR AUTORSKI- współpracujemy z ekipami remontowo budowlanymi na terenie Krakowa. W przypadku skorzystania z ich usług, nadzór autorski otrzymują Państwo w ramach wariantu projektu kompleksowego. ";

export const intro3 =
  "Uzyskane uprawnienia i zdobyte doświadczenie pozwalają Nam również realizować zadania z zakresu architektury kubaturowej i urbanistyki. Projektujemy wyjątkową architekturę dostosowaną do indywidualnych potrzeb klientów. Wszystkie projekty są dla nas tematami nad którymi pracujemy z pasją realizując marzenia klientów.";
export const intro4 =
  "Każdy proces projektowy rozpoczynamy od ANALIZ I PRZYGOTOWANIA INWESTYCJI, poprzez OPRACOWANIE KONCEPCJI, aż do załatwień formalnych (min. opracowanie PROJEKTÓW BUDOWLANYCH).";
export const case5 =
  "W ramach PRZYGOTOWANIA INWESTYCJI przygotowujemy: analizy działek pod względem ich możliwości inwestycyjnych (zgodność z warunkami zabudowy lub miejscowym planem zagospodarowania przestrzennego), przygotowanie wniosków w imieniu inwestora do gestorów sieci, przygotowanie wniosków o uzyskanie warunków zabudowy.";
export const case6 =
  "Na KONCEPCJĘ składają się rysunki będące podstawą do opracowania projektu budowlanego oraz widoki 3d przedstawiające zamierzenie. Wstępne rysunki techniczne obejmują plan zagospodarowania, rzuty budynku, elewacje, przekroje i zestawienia powierzchni. Na tym etapie w imieniu inwestorów zlecamy wykonanie mapy do celów projektowych oraz (jeśli to konieczne) wykonanie badań geotechnicznych gruntu).";
export const case7 =
  "Następnie koordynujemy PROJEKT BUDOWLANY aby uzyskać wszelkie niezbędne decyzje urzędowe w celu uzyskania ostatecznej decyzji pozwolenia na budowę. Wykonujemy pełen zakres usług projektowych, w tym w zależności od potrzeb: opracowanie kompleksowego wielobranżowego projektu budowlanego wraz z koordynacją branż,  doradztwo w zakresie przepisów i norm, doboru konstrukcji i materiałów budowlanych, oferujemy opiniowanie rzeczoznawcze projektów i rozwiązań.";
