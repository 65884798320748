import React, { Component } from "react";
import { Box } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import app from "../../firebase";
import { IsMobileContext } from "../contexts/is-mobile-context";
import LoadingSpinner from "../common/loading-spinner/loading-spinner";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./project-browser.css";
import { Carousel } from "react-responsive-carousel";

class ProjectBrowser extends Component {
  constructor(props) {
    super(props);
    this.state = { photos: [], gallery: [], isLoading: true, open: false };
    this.openCarousel = this.openCarousel.bind(this);
    this.closeCarousel = this.closeCarousel.bind(this);
  }

  componentDidMount() {
    this.getData(this.props.match.params.id);
  }

  async getData(entryId) {
    const photos = [];
    const gallery = [];
    try {
      await app.content
        .get({ schemaKey: "portfolio", populate: true, entryId: entryId })
        .then(result => {
          if (result) {
            const imageDeck = result.imageDeck;
            imageDeck.forEach((photo, index) => {
              photos.push({
                title: photo.title,
                img: photo.image[0].url,
                description: photo.description
              });
              gallery.push(
                <div key={index}>
                  <img
                    style={{ height: "100%", width: "auto" }}
                    src={photo.image[0].url}
                    alt={photo.image[0].url}
                  />
                </div>
              );
            });
          }
        })
        .then(() => {
          this.setState({
            photos: photos,
            gallery: gallery,
            isLoading: false,
            open: true
          });
        });
    } catch (error) {
      console.log(error);
    }
  }

  openCarousel() {
    this.setState({ open: true });
  }

  closeCarousel(e) {
    if (e.key === "Escape") {
      this.setState({ open: false });
    }
  }

  render() {
    return (
      <IsMobileContext.Consumer>
        {isMobile => (
          <Box justify="center">
            {this.state.isLoading ? (
              <LoadingSpinner />
            ) : (
              <Carousel
                width={"90vw"}
                showThumbs={false}
                showStatus={false}
                useKeyboardArrows
              >
                {this.state.gallery}
              </Carousel>
            )}
          </Box>
        )}
      </IsMobileContext.Consumer>
    );
  }
}

export default withRouter(ProjectBrowser);
