import React from "react";
import { Grow } from "@material-ui/core";
import { IsMobileContext } from "../contexts/is-mobile-context";
import background from "../../assets/background.jpg";
import "./landing.css";

export const Landing = () => {
  return (
    <IsMobileContext.Consumer>
      {isMobile => (
        <Grow in={true} timeout={1000}>
          <div className="home" style={isMobile ? {} : {}}>
            <img className="background" src={background} alt="" />
          </div>
        </Grow>
      )}
    </IsMobileContext.Consumer>
  );
};
