import React from "react";
import loading from "../../../assets/hexagon.png";
import "./loading-spinner.css";
import { Grow } from "@material-ui/core";

function LoadingSpinner() {
  return (
    <div className="loading-spinner">
      <Grow in={true} timeout={1000}>
        <img className="loading-spinner-img" src={loading} alt="" />
      </Grow>
    </div>
  );
}

export default LoadingSpinner;
