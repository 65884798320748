import React from "react";
import { NavLink } from "react-router-dom";

export const HeaderDesktop = () => {
  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <NavLink
        className="header-link-text"
        activeStyle={{ borderBottom: "solid 3px #148572" }}
        to="/o-nas"
      >
        O NAS
      </NavLink>
      <NavLink
        className="header-link-text"
        activeStyle={{ borderBottom: "solid 3px #148572" }}
        to="/portfolio"
      >
        PORTFOLIO
      </NavLink>
      <NavLink
        className="header-link-text"
        activeStyle={{ borderBottom: "solid 3px #148572" }}
        to="/oferta"
      >
        OFERTA
      </NavLink>
      <NavLink
        className="header-link-text"
        activeStyle={{ borderBottom: "solid 3px #148572" }}
        to="/kontakt"
      >
        KONTAKT
      </NavLink>
    </div>
  );
};

export default HeaderDesktop;
