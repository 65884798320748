import React from "react";
import { Slide, AppBar, Box } from "@material-ui/core";
import "./header.css";
import { HeaderContent } from "./header-content";

export const Header = props => {
  return (
    <Slide in={props.in} timeout={500}>
      <AppBar position="fixed" color="default">
        <Box display="flex" justifyContent="center" width="100%">
          <HeaderContent />
        </Box>
      </AppBar>
    </Slide>
  );
};
