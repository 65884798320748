import React from "react";
import { IsMobileContext } from "../../contexts/is-mobile-context";
import "./space.css";

export const Space = () => {
  return (
    <IsMobileContext.Consumer>
      {isMobile => <div className={isMobile ? "space-mobile" : "space"} />}
    </IsMobileContext.Consumer>
  );
};
