import React from "react";
import "./footer.css";
import { AppBar, Box } from "@material-ui/core";

export const Footer = () => {
  return (
    <AppBar position="relative" color="default" className="footer">
      <Box
        display="flex"
        maxWidth={1300}
        width={"100%"}
        alignItems="center"
        direction="row"
      >
        <a
          className="social-icon"
          href="https://www.facebook.com/sohodesignprojekty"
        >
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Facebook_icon_%28black%29.svg/512px-Facebook_icon_%28black%29.svg.png" alt="" />
        </a>
        <a
          className="social-icon"
          href="https://www.instagram.com/sohodesign_poland/?igshid=4hs6wxhq3c3y&fbclid=IwAR2ZyzQsCRarfdadPAErxmfTvxqH0fcyc_Gjgf2MinZOS7hmf1WIbkWilbc"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/CIS-A2K_Instagram_Icon_%28Black%29.svg/512px-CIS-A2K_Instagram_Icon_%28Black%29.svg.png"
            alt=""
          />
        </a>
      </Box>
    </AppBar>
  );
};
