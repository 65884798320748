import React from "react";
import { Grow } from "@material-ui/core";
import "./title.css";

const startingTimeout = 500;

function Title(props) {
  return (
    <Grow in={props.shouldRender} timeout={startingTimeout}>
      <h2 className="title">
        {props.value} 
        <div className="line" />
      </h2>
    </Grow>
  );
}

export default Title;
