import React, { Component } from "react";
import { Grow } from "@material-ui/core";
import { IsMobileContext } from "../../contexts/is-mobile-context";
import "./portoflio-tile.css";

export default class PortfolioTile extends Component {
  constructor(props) {
    super(props);
    this.state = { hovered: false };
  }

  onMouseOver = () => this.setState({ hovered: true });
  onMouseOut = () => this.setState({ hovered: false });

  render() {
    return (
      <IsMobileContext.Consumer>
        {isMobile => (
          <Grow in={true} timeout={1000}>
            <div
              onMouseOver={() => this.onMouseOver()}
              onMouseOut={() => this.onMouseOut()}
              onClick={() => this.props.onClick()}
              className="portfolio-tile-container"
            >
              <img alt="" className="portfolio-tile" src={this.props.image} />
              <Grow in={this.state.hovered} timeout={800}>
                <div className="portfolio-tile-background">
                  <Grow in={this.state.hovered} timeout={1200}>
                    <div className="portfolio-tile-description-line" />
                  </Grow>
                  <Grow in={this.state.hovered} timeout={1400}>
                    <div className="portfolio-tile-description">
                      {this.props.description}
                    </div>
                  </Grow>
                </div>
              </Grow>
            </div>
          </Grow>
        )}
      </IsMobileContext.Consumer>
    );
  }
}
