import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors/red";
import { CssBaseline, useScrollTrigger, Grow } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";

import { Header } from "./components/header/header";
import { Footer } from "./components/footer/footer";
import { IsMobileContext } from "./components/contexts/is-mobile-context";
import { ProjectsContext } from "./components/contexts/project-context";
import { useWindowSize } from "./components/hooks/useWindowSize";
import ScrollToTopWithRouter from "./components/common/scroll-to-top/scroll-to-top-with-router";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import LoadingSpinner from "./components/common/loading-spinner/loading-spinner";
import Routes from "./routes";
import app from "./firebase";
import "./app.css";
import { ScrollTopWithButton } from "./components/common/scroll-to-top/scroll-to-top-with-button";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["sans-serif", '"Montserrat"'].join(",")
  },
  palette: {
    primary: { main: "#284B63" },
    secondary: red
  }
});

function App() {
  const [projects, setProjects] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      const projects = [];
      try {
        await app.content
          .get({ schemaKey: "portfolio", populate: true })
          .then(result => {
            if (result) {
              for (const key in result) {
                const project = result[key];
                projects.push({
                  id: project.id,
                  title: project.imageDeck[0].title,
                  img: project.imageDeck[0].image[0].url,
                  description: project.imageDeck[0].description
                });
              }
            }
          })
          .then(() => {
            setProjects(projects);
            setIsLoading(false);
          });
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const windowSize = useWindowSize();
  const scrollTrigger = useScrollTrigger();
  const isMobile = windowSize.width < 900 ? true : false;
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <ProjectsContext.Provider value={projects}>
        <IsMobileContext.Provider value={isMobile}>
          <BrowserRouter>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <Grow in={true} timeout={1000}>
                <div className="app">
                  <div id="top">
                    <Header in={!scrollTrigger} />
                  </div>
                  <ScrollToTopWithRouter />
                  <ScrollTopWithButton>
                    <Fab
                      color="primary"
                      size="small"
                      aria-label="scroll back to top"
                    >
                      <KeyboardArrowUpIcon />
                    </Fab>
                  </ScrollTopWithButton>
                  <Routes />
                  <Footer />
                </div>
              </Grow>
            )}
          </BrowserRouter>
        </IsMobileContext.Provider>
      </ProjectsContext.Provider>
    </MuiThemeProvider>
  );
}

export default App;
