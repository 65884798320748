import React, { useState } from "react";
import { IconButton, Drawer } from "@material-ui/core";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";

export const HeaderMobile = () => {
  const [isOpen, setIsOpen] = useState();

  const toggleDrawer = value => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(value);
  };

  return (
    <div>
      <IconButton
        color="inherit"
        aria-label="Menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="top" open={isOpen} onClose={toggleDrawer(false)}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            height: "5em"
          }}
        >
          <Link
            className="header-link-text"
            to="/o-nas"
            onClick={toggleDrawer(false)}
          >
            O NAS
          </Link>
          |
          <Link
            className="header-link-text"
            to="/portfolio"
            onClick={toggleDrawer(false)}
          >
            PORTFOLIO
          </Link>
          |
          <Link
            className="header-link-text"
            to="/oferta"
            onClick={toggleDrawer(false)}
          >
            OFERTA
          </Link>
          |
          <Link
            className="header-link-text"
            to="/kontakt"
            onClick={toggleDrawer(false)}
          >
            KONTAKT
          </Link>
        </div>
      </Drawer>
    </div>
  );
};
