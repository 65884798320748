import React from "react";
import { Link } from "react-router-dom";
import logoBig from "../../assets/logo-big.png";
import logoSmall from "../../assets/logo-small.svg";
import { HeaderMobile } from "./header-mobile";
import { HeaderDesktop } from "./header-desktop";
import { IsMobileContext } from "../contexts/is-mobile-context";
import "./header.css";

export const HeaderContent = () => {
  return (
    <IsMobileContext.Consumer>
      {isMobile => (
        <div className="header-content">
          <Link className="header-link-image" to="/">
            {isMobile ? (
              <img src={logoSmall} alt="" className="logo-small" />
            ) : (
              <img src={logoBig} alt="" className="logo-big" />
            )}
          </Link>
          {isMobile ? <HeaderMobile /> : <HeaderDesktop />}
        </div>
      )}
    </IsMobileContext.Consumer>
  );
};
