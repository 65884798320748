import React, { useRef } from "react";
import { Link, withRouter } from "react-router-dom";

import { Grow, Container, Box, Button } from "@material-ui/core";
import { useOnScreen } from "../hooks/useOnScreen";
import { IsMobileContext } from "../contexts/is-mobile-context";
import OfferDesign from "./offer-design";
import OfferArchitecture from "./offer-architecture";
import "./offer.css";

const Offer = props => {
  const ref = useRef();
  const onScreen = useOnScreen(ref, "-300px");
  const onScreenMobile = useOnScreen(ref, "-100px");
  let isInOfferSection = props.location.pathname === "/oferta";

  return (
    <div ref={ref}>
      <IsMobileContext.Consumer>
        {isMobile => (
          <Grow in={isMobile ? onScreenMobile : onScreen} timeout={1000}>
            <div
              className="offer-container"
              style={isInOfferSection ? { marginTop: "10em" } : {}}
            >
              <Box
                className="offer"
                display="flex"
                maxWidth={1300}
                flexDirection={isMobile ? "column" : "row"}
              >
                <Container justify="center">
                  <OfferDesign />
                </Container>
                <Container justify="center">
                  <OfferArchitecture />
                </Container>
              </Box>
              {isInOfferSection ? (
                <div />
              ) : (
                <Link className="offer-link" to="/oferta">
                  <Button variant="outlined" color="primary">
                    WIĘCEJ
                  </Button>
                </Link>
              )}
            </div>
          </Grow>
        )}
      </IsMobileContext.Consumer>
    </div>
  );
};

export default withRouter(Offer);
