import React from "react";

export const ContactInfo = () => {
  return (
    <div className="contact-info">
      <h1 className="title">SOHO DESIGN Wioleta Petynia</h1>
      <h4 className="text">Ul. Wincentego Danka 7/5, 31-229 Kraków</h4>
      <br />
      <h4 className="text">Biuro:</h4>
      <h4 className="text">796 106 046</h4>
      <h4 className="text">biuro@sohodesign.com.pl</h4>
      <br />
      <h4 className="text">Wiola:</h4>
      <h4 className="text">796 106 008</h4>
      <h4 className="text">w.petynia@sohodesign.com.pl</h4>
    </div>
  );
};
