import React from "react";
import { withRouter } from "react-router-dom";
import { Grow } from "@material-ui/core";
import "./portfolio.css";
import { Link } from "react-router-dom";
import PortfolioTile from "./tile/portfolio-tile";
import { IsMobileContext } from "../contexts/is-mobile-context";
import { ProjectsContext } from "../contexts/project-context";

function Portfolio(props) {
  let shouldRender = props.location.pathname === "/portfolio";
  return (
    <Grow in={props.shouldRender || shouldRender} timeout={1000}>
      <div className="portfolio-container">
        <ProjectsContext.Consumer>
          {projects => (
            <IsMobileContext.Consumer>
              {isMobile => (
                <div className={isMobile ? "portfolio-mobile" : "portfolio"}>
                  {projects &&
                    projects.length > 0 &&
                    projects.map((tile, index) => (
                      <Link
                        key={index}
                        className="tile-link"
                        to={`/portfolio/${tile.id}`}
                      >
                        <PortfolioTile
                          key={index}
                          image={tile.img}
                          description={tile.title}
                          onClick={() => {}}
                        />
                      </Link>
                    ))}
                </div>
              )}
            </IsMobileContext.Consumer>
          )}
        </ProjectsContext.Consumer>
      </div>
    </Grow>
  );
}

export default withRouter(Portfolio);
