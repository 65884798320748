import React from "react";
import SmallText from "../common/small-text/small-text";
import * as offerText from "./offer-content";
import { Grow, Container } from "@material-ui/core";
import designImg from "../../assets/offer-design.png";
import withRouter from "react-router-dom/withRouter";
import Title from "../common/title";

function OfferDesign(props) {
  let isInOfferSection = props.location.pathname === "/oferta";
  return (
    <Grow in={true} timeout={1000}>
      <Container>
        <div className="centered">
          <Title value="PROJEKTOWANIE I ARANŻACJA WNĘTRZ" shouldRender={true} />
          <img style={{ width: "20em" }} src={designImg} alt="" />
        </div>
        <br />
        <SmallText text={offerText.intro1} />
        {isInOfferSection ? (
          <div>
            <SmallText text={offerText.intro2} />
            <SmallText text={offerText.case1} />
            <SmallText text={offerText.case2} />
            <SmallText text={offerText.case3} />
            <SmallText text={offerText.case4} />
          </div>
        ) : (
          <div />
        )}
      </Container>
    </Grow>
  );
}

export default withRouter(OfferDesign);
