import React, { useRef } from "react";
import { Landing } from "../landing/landing";
import Portfolio from "../portfolio/portfolio";
import Offer from "../offer/offer";
import Title from "../common/title";
import { useOnScreen } from "../hooks/useOnScreen";
import Hexagon from "../common/hexagon/hexagon";
import { Box } from "@material-ui/core";
import { Space } from "../common/space/space";

export const Home = () => {
  const ref1 = useRef();
  const onScreen1 = useOnScreen(ref1, "-100px");
  const ref2 = useRef();
  const onScreen2 = useOnScreen(ref2, "-100px");
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Landing />
      <Space />
      <Box
        maxWidth={1300}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Hexagon shouldRender={onScreen1} />
        <Title value="NASZE REALIZACJE" shouldRender={onScreen1} />
        <div ref={ref1} style={{ width: "100%" }}>
          <Portfolio shouldRender={onScreen1} />
        </div>
        <Space />
        <Hexagon shouldRender={onScreen2} />
        <div ref={ref2}>
          <Offer />
        </div>
      </Box>
    </Box>
  );
};
