import React, { useState } from "react";
import SmallText from "../common/small-text/small-text";
import * as content from "./about-content";
import AboutImg from "../../assets/about.png";
import { Grow, Box } from "@material-ui/core";
import "./about.css";
import { IsMobileContext } from "../contexts/is-mobile-context";

function About() {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <IsMobileContext.Consumer>
      {isMobile => (
        <Grow in={!isLoading} timeout={1000}>
          <Box
            maxWidth={1300}
            className="about"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={isMobile ? "column" : "row"}
          >
            <img
              className={isMobile ? "about-img-mobile" : "about-img"}
              src={AboutImg}
              alt=""
              onLoad={setIsLoading(false)}
            />
            <div className="about-text">
              <SmallText text={content.text1} />
              <SmallText text={content.text2} />
              <SmallText text={content.text3} />
            </div>
          </Box>
        </Grow>
      )}
    </IsMobileContext.Consumer>
  );
}

export default About;
