import React from "react";
import SmallText from "../common/small-text/small-text";
import * as offerText from "./offer-content";
import { Grow, Container } from "@material-ui/core";
import architectureImg from "../../assets/offer-arch.png";
import withRouter from "react-router/withRouter";
import Title from "../common/title";

function OfferArchitecture(props) {
  let isInOfferSection = props.location.pathname === "/oferta";
  return (
    <Grow in={true} timeout={1000}>
      <Container>
        <div className="centered">
          <Title value="PROJEKTOWANIE ARCHTEKTONICZNE" shouldRender={true} />
          <img style={{ width: "20em" }} src={architectureImg} alt="" />
        </div>
        <br />
        <SmallText text={offerText.intro3} />
        {isInOfferSection ? (
          <div>
            <SmallText text={offerText.intro4} />
            <SmallText text={offerText.case5} />
            <SmallText text={offerText.case6} />
            <SmallText text={offerText.case7} />
          </div>
        ) : (
          <div />
        )}
      </Container>
    </Grow>
  );
}

export default withRouter(OfferArchitecture);
