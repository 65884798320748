import React, { useState } from "react";
import { Grow, Grid } from "@material-ui/core";
import { ContactForm } from "./contact-form";
import "./contact.css";
import { ContactInfo } from "./contact-info";
import ReactMapGL, { Marker } from "react-map-gl";
import LocationOnIcon from "@material-ui/icons/LocationOn";

export const Contact = () => {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [zoom, setZoom] = useState(18);

  const [viewPort, setViewport] = useState({
    latitude: 50.09393775628472,
    longitude: 19.9265945288161,
    zoom: 18,
  });

  const mapbox_api_key = process.env.REACT_APP_MAPBOX_API_KEY;

  return (
    <Grow in={isMapLoaded} timeout={1000}>
      <div className="page-container">
        <div className="map-container">
          <ReactMapGL
            {...viewPort}
            onLoad={() => setIsMapLoaded(true)}
            width="100%"
            height="100%"
            mapboxApiAccessToken={mapbox_api_key}
            onViewportChange={setViewport}
          >
            <Marker
              latitude={50.09393775628472}
              longitude={19.9265945288161}
              offsetLeft={0}
              offsetTop={-20}
            >
              <LocationOnIcon style={{ fontSize: 50 }} />
            </Marker>
          </ReactMapGL>
        </div>
        <Grid container justify="center" alignItems="flex-start">
          <Grid item xs={12} sm={3}>
            <ContactInfo />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ContactForm />
          </Grid>
        </Grid>
      </div>
    </Grow>
  );
};
