import React, { useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  TextField,
  Snackbar,
  SnackbarContent,
  makeStyles
} from "@material-ui/core";
import { send } from "emailjs-com";

const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "Required";
  } else if (values.firstName.length > 15) {
    errors.firstName = "Must be 15 characters or less";
  }

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.message) {
    errors.message = "Required";
  } else if (values.message.length > 20) {
    errors.message = "Must be 20 characters or less";
  }

  return errors;
};

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "5px",
    backgroundColor: "white",
    color: "black"
  }
}));

export const ContactForm = () => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);

  const handleCloseSuccess = event => {
    setOpenSuccess(false);
  };

  const handleCloseFailure = event => {
    setOpenFailure(false);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      email: "",
      message: ""
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      send(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        {
          name: values.firstName,
          message_html: values.message,
          from_name: values.email
        },
        process.env.REACT_APP_EMAIL_USER_ID
      )
        .then(res => {
          resetForm({});
          setOpenSuccess(true);
        })
        .catch(err => {
          setOpenFailure(true);
          console.error(
            "Oh well, you failed. Here some thoughts on the error that occured:",
            err
          );
        });
    }
  });

  const classes = useStyles();

  return (
    <div>
      <form className="contact-form" onSubmit={formik.handleSubmit}>
        <TextField
          label="Imię"
          id="firstName"
          name="firstName"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstName}
          fullWidth
        />
        {formik.touched.firstName && formik.errors.firstName ? (
          <div>{formik.errors.firstName}</div>
        ) : null}
        <TextField
          label="Email"
          id="email"
          name="email"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          fullWidth
        />
        {formik.touched.email && formik.errors.email ? (
          <div>{formik.errors.email}</div>
        ) : null}
        <TextField
          label="Napisz do nas!"
          id="message"
          name="message"
          type="message"
          multiline
          rows="4"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.message}
          fullWidth
        />
        {formik.touched.message && formik.errors.message ? (
          <div>{formik.errors.message}</div>
        ) : null}
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          style={{ margin: "2em" }}
        >
          WYŚLIJ
        </Button>
      </form>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <SnackbarContent classes={classes} message="Wysłano wiadomość!" />
      </Snackbar>
      <Snackbar
        open={openFailure}
        autoHideDuration={6000}
        onClose={handleCloseFailure}
      >
        <SnackbarContent
          classes={classes}
          message="Nie udało się wysłać wiadomości!"
        />
      </Snackbar>
    </div>
  );
};
